<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: false,
  },
  isBottom: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: "auto",
  },
});


const emit = defineEmits(['update:modelValue']);
const show = ref(false);

const close = () => {
  emit('update:modelValue', false);
};

const handleKeydown = (event) => {
  if (event.key === 'Escape') {
    close();
  }
};


onMounted(() => {
  if (props.modelValue) {
    show.value = true;
  }
  window.addEventListener('keydown', handleKeydown);
});

</script>
<template>
   <teleport to="body">
      <div class="relative z-[101]">
          <div @click.stop ref="container" class="w-full h-full fixed inset-0" 
                                            :class="`${modelValue? ``:`invisible`}`">
              <!-- Background overlay -->
                <div 
                  @click.stop="close" 
                  ref="bg" 
                  class="w-full h-full duration-500 ease-out transition-all inset-0 absolute bg-gray-900 delay-200 opacity-50"
                  :class="`${modelValue? `opacity-50`:``}`">
                </div>

                <!-- Slide-over panel -->
                <div 
                  ref="slideover" 
                  :class="`w-full sm:w-96 bg-white text-black h-${width} ${width=='full'?'max-h-full':'max-h-[80%]'} overflow-scroll absolute duration-300 ease-linear transition-all 
                  ${isBottom? `bottom-0 ${modelValue? `translate-y-0`:`translate-y-full`}`
                  :`top-0 ${modelValue? `translate-y-0`:`-translate-y-full`}`}`">
                  <!-- Slide-over content here -->
                  <slot></slot>
                </div>
          </div>
        </div>
    </teleport>
</template>
